.footer {

  background: #183349;
  padding: 40px 0;

  h4.sub-header {
    font-size: 18px;
    line-height: 40px;
    color: #FFFFFF;
  }

  a.footer-item {
    display: block;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    color: rgba(255, 255, 255, 0.48);
  }

  a.footer-item:hover {
    color: rgba(255, 255, 255, 0.72);
  }

}
