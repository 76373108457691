@import "../../style/app";

.about {

  position: relative;
  background-color: $light-grey;

  div.title-background-parent {
    height: 616px;

    div.title-background-shape {
      height: 616px;
      opacity: 1;

      div.title-background-image {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        div.title-background-overlay {
          height: 100%;
          background: $kujakuja-yellow;
          opacity: 0.88;
        }
      }
    }
  }

  .title-parent {

    margin: 0 auto 100px;
    padding-top: 100px;

    .title-parent-row {
      margin-left: -30px;
    }

    @media screen and (max-width: 1199px) {
      .title-parent-row {
        margin-left: -120px;
      }
    }
    @media screen and (max-width: 990px) {
      .title-parent-row {
        margin-left: 0;
      }
    }

    p.sub-title {
      max-width: 750px;
      font-size: 16px;
      line-height: 24px;
    }

  }

  .action-cards-parent {

    text-align: center;
    margin: 10px auto;
    min-width: 460px;

    .action-card {
      margin: auto;
      width: 460px;
      min-width: 460px;
      height: 220px;
      background: #FFFFFF;
      box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
      border-radius: 12px;
      text-align: center;
      padding: 20px 30px;

      h2 {
        margin-top: 15px;
        font-size: 34px;
        line-height: 56px;
        text-align: center;
      }

      h3 {
        margin-top: -12px;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 25px;
      }

      p {
        margin-top: 10px;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
      }

      span.plus {
        font-size: 1.5rem;
        margin-left: 3px;
      }

    }

  }

  .about-map-parent {

    padding-bottom: 50px;

    .about-map {

      font-family: -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

      #kujakuja-map {
        margin: auto;
        width: 90%;
        height: 500px;
      }

      text-align: center;
      margin-top: 100px;
      position: relative;

      img.map {
        width: 80%;
      }

      h3 {
        font-size: 32px;
        line-height: 40px;
        width: 100%;
        text-align: left;
      }

      div.blurb-parent {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        top: 60px;
        width: 100%;
        left: 20px;


        p.blurb {
          font-size: 13px;
          line-height: 20px;
          text-align: left;
        }

        img.ellipse {
          width: 14px;
          height: 14px;
        }

        ul {
          text-align: left;
          list-style: none;
          white-space: nowrap;
        }
      }

    }

  }

  .teams {

    margin-top: 60px;
    text-align: center;

    h3 {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    p {
      max-width: 700px;
      margin: auto;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #2B3841;
    }

    div.video-embed {
      margin-top: 60px;
      height: 629px;
      position: relative;
      border-radius: 12px;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }

  .wide-parent {
    width: 100%;
    background-color: #FFFFFF;
  }

  @media screen and (min-width: 992px) {
    .action-cards-parent:nth-child(2) {
      .action-card {
        float: left;
      }
    }
    .action-cards-parent:nth-child(1) {
      .action-card {
        float: right;
      }
    }
  }

  @media screen and (max-width: 991px) {

    .video-embed {
      margin-top: 35px;
      height: 400px !important;
    }


  }

  @media screen and (max-width: 500px) {

    .action-cards-container {
      margin-top: -60px;

      .action-cards-parent {
        min-width: 400px;

        .action-card {
          width: 400px !important;
          min-width: 400px !important;

          h2 {
            font-size: 30px;
            line-height: 50px;
          }

          h3 {
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .about-map-parent {
      padding-bottom: 30px;

      .about-map {
        margin-top: 30px;
      }
    }
    #kujakuja-map {
      height: 300px !important;
    }
    .video-embed {
      margin-top: 30px;
      height: 350px !important;
      margin-bottom: -30px;
    }
  }

  @media screen and (max-width: 420px) {

    .action-cards-container {
      margin-top: -80px;

      .action-cards-parent {
        min-width: 380px;

        .action-card {
          width: 340px !important;
          min-width: 340px !important;

          h2 {
            font-size: 27px;
            line-height: 40px;
          }

          h3 {
            font-size: 13px;
            line-height: 13px;
            margin-bottom: 18px;
          }
        }
      }

    }

    .about-map-parent {
      padding-bottom: 20px;

      .about-map {
        margin-top: 20px;
      }
    }
    #kujakuja-map {
      height: 260px !important;
    }
    .video-embed {
      margin-top: 10px;
      height: 300px !important;
    }
  }

}
