@import "../../style/app";

.header {
  background-color: $kujakuja-yellow;
}

.navbar {
    min-height: 104px;
}

.dropdown{
    cursor: pointer;
    font-family: 'Nunito Sans', sans-serif;
    color: $kujakuja-black !important;
    font-size: 16px;
    margin-right: 16px;
    padding: .3rem 1rem;
}

.arrow-up {
  width: 0;
  height: 0;
  position: absolute;
  top: -7px;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #FFFFFF transparent;
  padding: 0 !important;
}

@media screen and (min-width: 992px) {
  .lng-arrow-up {
    left: 170px;
  }

  .lng-dropdown-menu {
    left: -150px;
  }
}

.dropdown-menu {
  padding: 25px;
  min-width: 270px;
  border-color: #FFFFFF;
  box-shadow: 0 10px 12px -6px rgba(12, 16, 19, 0.24);
  border-radius: 8px;

  .row{
    cursor:pointer;
    border-radius:5px;
  }

  .row:hover{
    background-color: #f2f2f2;
  }

  h5{
    margin: 6px 0;
    font-size: 1.15rem;
    font-weight: bold;
  }

  div{
    padding:3px;
    font-size: 0.75rem;
  }

  .right{
    margin-top:15px;
    text-align: right;
  }

}

.dropdown-item.active, .dropdown-item:active {
  background-color: #000000;
}

.customer-logo {
    max-width: 65px;
    max-height: 48px;
    margin-left: -5px;
}

.customer-logo-nav-link {
    padding: 0;
}

.navbar-toggler {
    padding: 0;
    margin-right: 16px;
    border: none;
    outline: none;
}

.navbar-toggler-icon {
    width: 32px;
    margin-right: 0;
    background-image: url('../../img/hamburger.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.nav-link {
    padding: .3rem 1rem;
}

nav .navbar-nav li a {
    font-family: 'Nunito Sans', sans-serif;
    color: $kujakuja-black !important;
    font-size: 16px;
    margin-right: 16px;
}

nav .navbar-nav li a.navbar_active {
    border-bottom: $kujakuja-black 2px solid;
}

nav .collapse.show .navbar-nav {
    margin-top: 32px;
    margin-bottom: 32px;

  .dropdown{
    padding-left: 40px;
  }

}

nav .collapse.show .navbar-nav li {
    padding-left: 24px;
}

nav .collapse.show .navbar-nav li a {
    margin: 16px;
    padding: 0;
}

 @media screen and (max-width: 420px) {

   .dropdown-menu {
     left: -35px !important;
   }

 }
