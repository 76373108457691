@import "../../style/app";

.partners {

  position: relative;
  background-color: $light-grey;
  padding-bottom: 10px;

  .title-parent {

    margin: 0 auto 70px;
    padding-top: 70px;

    p.sub-title {
      max-width: 800px;
      font-size: 16px;
      line-height: 24px;
    }

  }

  .cards-parent {
    min-width: 350px;
    text-align: center;
  }


  @media screen and (max-width: 1199px) {

    .cards-parent {
      margin: auto;
    }

  }


}
