@import "../../style/app";

.contact {

  position: relative;
  background-color: $light-grey;
  padding-bottom: 100px;

  button.cta {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    border: none;
    background: transparent;
  }

  .title-parent {

    margin: 0 auto 70px;
    padding-top: 70px;

    p.sub-title {
      max-width: 800px;
      font-size: 16px;
      line-height: 24px;
    }

  }

  .cards-row{
    justify-content: center;
  }

  .action-cards-parent {

    text-align: center;
    min-width: 550px;

    .footer-action-card {
      width: 550px;
      height: 300px;
      background: #FFFFFF;
      box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
      border-radius: 12px;
      text-align: center;
      margin-bottom: 20px;
      overflow: hidden;

      .action-card-content {
        height: 248px;
        padding: 40px;

        h2 {
        margin:10px 0;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        }

        p {
          margin-top: 30px;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }

      .action-card-footer {
        background-color: $light-grey;
        padding: 14px 20px;
      }

    }

  }

  @media screen and (max-width: 600px) {

    .action-cards-parent {

      .footer-action-card {
        width: 450px;
        height: 300px;
        margin: 0 auto 20px auto;

      }
    }

  }

  @media screen and (max-width: 450px) {

    .action-cards-parent {

      .footer-action-card {
        width: 330px;
        margin: 0 auto 20px auto;

      }
    }

  }

}
