@import "../../style/app";

.work-with-us {

  position: relative;
  background-color: $light-grey;

  .title-parent {

    margin: 0 auto 70px;
    padding-top: 70px;

    p.sub-title {
      max-width: 800px;
      font-size: 16px;
      line-height: 24px;
    }

  }

  .nav-tabs {
    align-items: stretch;
    flex-direction: row;

    .nav-item {
      flex: 1;

      .nav-link {
        height: 100%;
      }
    }

    .nav-item:last-child .nav-link {
      margin-right: 0;
    }
  }

  .nav-tabs .nav-link {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 12px 12px 0px 0px;
    margin-right: 20px;
    padding: 20px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(23, 29, 33, 0.48);
    cursor: pointer;
    border: none;
  }

  .nav-tabs .nav-link.active {
    background: #FFFFFF;
    color: #2B3841;
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:not(.active):hover {
    background: rgba(255, 255, 255, 0.64);
  }

  .tab-content .tab-pane {
    background: #FFFFFF;
    border-radius: 0px 0px 12px 12px;
    padding: 40px;

    .image-container {
      position: relative;
      float: right;
      margin-left: 60px;
      margin-bottom: 60px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 612px;
      }

      .overlay {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(43, 56, 65, 0.08);
      }
    }

  }

  h4 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    color: #2B3841;
    margin-bottom: 30px;
  }

  a.cta-get-in-touch {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  #success-stories-mobile {

    display: none;
    margin-top: -60px;
    margin-bottom: -60px;

    .success-stories-container {

      border: 1px solid #FFFFFF;
      border-radius: 10px;
      background-color: #FFFFFF;
      padding: 20px;
      margin: 20px;

      img {
        max-width: 100%;
        display: block;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
        margin: 30px auto;
      }
    }
  }

  @media screen and (max-width: 767px) {

    #success-stories-desktop {
      display: none;
    }
    #success-stories-mobile {
      display: block;
    }

    a.nav-link {
      font-size: 14px !important;
      line-height: 18px !important;
    }
    .image-container {
      img {
        width: 420px !important;
      }
    }
  }

  @media screen and (max-width: 528px) {

    a.nav-link {
      font-size: 12px !important;
      line-height: 14px !important;
    }
    .image-container {
      img {
        width: 340px !important;
      }
    }
  }

  @media screen and (max-width: 420px) {

    .tab-content .tab-pane {
      padding: 30px;
    }

    .image-container {
      img {
        width: 280px !important;
      }
    }
  }

  .the-kk-process {

    margin-top: 100px;
    padding: 100px 0 150px;
    background-color: $kujakuja-yellow;

    h3 {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    p.subtext {
      text-align: center;
      margin-bottom: 60px;
    }

    .action-cards-parent {

      text-align: center;
      margin: 10px auto;
      min-width: 370px;

      .icon-action-card {
        width: 360px;
        height: 252px;
        background: #FFFFFF;
        box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
        border-radius: 12px;
        text-align: center;
        margin: 60px auto auto auto;
        padding: 60px 30px 20px;

        .action-card-icon {
          width: 73px;
          height: 73px;
          position: absolute;
          top: 24px;
          left: calc(50% - 36px);
          background-color: #00BA54;
          border-radius: 50%;

          svg {
            width: 32px;
            height: 32px;
            position: relative;
            top: 20px;
            color: #004C22;
          }
        }

        h2 {
          font-size: 24px;
          line-height: 32px;
          text-align: center;
        }

        p {
          margin-top: 10px;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
        }

      }

    }

    @media screen and (max-width: 528px) {

      .action-cards-parent {
        min-width: 340px;

        .icon-action-card {
          width: 330px;
          height: 252px;
          p {
            font-size: 14px;
          }
        }
      }

    }

    div#repeat-arrow-parent {

      position: relative;

      img#repeat-arrow {
        position: absolute;
        top: -470px;
        left: -40px;
      }

      div#repeat {
        width: 119px;
        height: 32px;
        position: absolute;
        top: 20px;
        left: 50%;
        margin-left: -60px;
        background: $kujakuja-black;
        border-radius: 16px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 32px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;

        #icon {
          font-size: 0.6rem;
          color: #EBEBEB;
        }

      }

    }

    @media screen and (max-width: 1200px) {
      div#repeat-arrow-parent {
        display: none;
      }
    }


  }

}
