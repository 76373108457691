@import "../../style/app";

.learn-more-parent {

  margin: 60px 0;
  text-align: center;
  padding: 60px;
  background: #00BA54;
  color: #004C22;
  border-radius: 12px;

  h1.learn-more {
    font-size: 30px;
    line-height: 40px;
  }

  a {
    color: #004C22;
  }

  span{
    margin-left:4px;
  }

}
