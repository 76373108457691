@import "variables";

h1, h2, h3, h4, h5, button, a {
  font-family: Nunito Sans;
}

p, ul {
  font-family: Open Sans;
}

h1, h2, h3, h4 {
  font-style: normal;
  font-weight: 900;
  color: $kujakuja-black;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

ul {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
}

a {
  font-style: normal;
  font-size: 16px;
  color: #000000;
}

a:hover {
  text-decoration: none;
}

h3.title {
  font-size: 48px;
  line-height: 56px;
}

div.title-background-parent {
  width: 100%;
  height: 506px;
  position: absolute;
  top: 0;
  overflow: hidden;

  div.title-background-shape {
      width: 140%;
      height: 506px;
      position: absolute;
      top: 0;
      left: -20%;
      padding: 0 20%;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      overflow: hidden;
      background-color: $kujakuja-yellow;
      opacity: 0.88;
  }

}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a.right-arrow svg, button.right-arrow svg {
  transition-duration: 0.24s;
  vertical-align: middle;
  margin-left: 2px;
}

a.right-arrow:hover, button.right-arrow:hover {
  opacity: 0.64;
}

a.right-arrow:hover svg, button.right-arrow:hover svg {
  margin-left: 14px;
}


@media screen and (max-width: 1200px) {
  div.title-background-shape {
    width: 180% !important;
    left: -40% !important;
  }
}

@media screen and (max-width: 900px) {
  div.title-background-shape {
    width: 200% !important;
    left: -50% !important;
  }
}

@media screen and (max-width: 575px) {
  .left-right-grid-column {
    max-width: 30px;
  }
  div.title-background-shape {
    width: 220% !important;
    left: -60% !important;
  }

}
