@import "../../style/app";

div.landing {

  position: relative;

  div.title-background-parent {
    height: 760px;

    div.title-background-shape {
      height: 760px;
      opacity: 1;

      div.title-background-image {
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        div.title-background-overlay {
          height: 100%;
          background: $kujakuja-yellow;
          opacity: 0.88;
        }
      }
    }
  }

  .title-parent {

    margin: 0 auto;
    padding-top: 80px;

    h3.title {
      font-size: 40px;
      line-height: 48px;
      max-width: 700px;
    }

    p.sub-title {
      margin-bottom: 40px;
      max-width: 700px;
    }

  }

  .data-map-parent {
    margin-top: 100px;
  }

  .slider {
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 500px;
    position: relative;
    border-radius: 10px;
    box-shadow: inset 0px -50px 10px rgba(0.5, 0.5, 0.5, 0.1);

    span {
      position: absolute;
      bottom: 12px;
      left: 20px;
      color: #FFFFFF;
      font-weight: 400;
    }

  }

  @media screen and (max-width: 800px) {
    .slider {
      height: 300px;

      span {
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .slider {
      height: 200px;

      span {
        font-size: 0.8rem;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .data-map-parent {
      margin-top:40px;
    }
  }

  .pagination {

    margin: 20px;
    display: flex;
    justify-content: center;
    position: relative;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #b7b7b7;
      margin: 3px;
      cursor: pointer;
    }

    .selected {
      background-color: #000000;
    }

  }

  .sub-section {

    padding: 30px;
    margin-top: 40px;

    h4 {
      font-size: 32px;
      line-height: 40px;
      margin-left: 20px;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: #2B3841;
      margin-left: 20px;
    }

    a {
      font-weight: 800;
    }

    img.summary {
      width: 650px;
      border-radius: 12px;
    }

    img.summary-portrait {
      height: 700px;
    }

    #vegetables-parent {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 999;
    }

    #vegetables-image {
      width: 112px;
      height: 44px;
      position: absolute;
      top: 45px;
      left: -60px;
    }

    #vegetables-arrow {
      width: 114px;
      height: 96px;
      position: absolute;
      bottom: 265px;
      right: -80px;
    }

    @media screen and (max-width: 1200px) {
      img.summary {
        width: 500px;
      }
      img.summary-portrait {
        height: 600px;
      }
      img.summary-portrait {
        height: 500px;
      }
      #vegetables-image {
        width: 90px;
        height: 35px;
        top: 32px;
        left: -39px;
      }
      #vegetables-arrow {
        width: 91px;
        height: 77px;
        bottom: 203px;
        right: -78px;
      }
    }

    @media screen and (max-width: 991px) {
      img.summary {
        width: 400px;
      }
      img.summary-portrait {
        height: 500px;
      }
      #vegetables-image {
        top: 3px;
        left: -68px;
      }
      #vegetables-arrow {
        bottom: 162px;
        right: -61px;
      }
    }

    @media screen and (max-width: 767px) {
      h4 {
        margin-top: 30px;
      }
      .subtitle-description {
        display: none;
      }
    }

    @media screen and (max-width: 575px) {
      #vegetables-grand-parent {
        display: none;
      }
    }

    @media screen and (max-width: 450px) {
      img.summary {
        width: 300px;
      }
      img.summary-portrait {
        height: 400px;
      }
    }

  }

  div.snapshots-parent {

    position: relative;
    overflow: hidden;
    margin-top: 100px;

    div.snapshots-background-shape {
      width: 140%;
      height: 90%;
      position: absolute;
      top: 0;
      left: -20%;
      padding: 0 20%;
      border-bottom-left-radius: 50%;
      border-bottom-right-radius: 50%;
      overflow: hidden;
      background-color: $light-grey;
    }

    #snapshot-card-3 {
      text-align: center;
      display: none;
    }

    #snapshot-card-1, #snapshot-card-2 {
      margin-top: -60px;
    }

    #snapshot-card-1-parent-col-1 {
      display: flex;
      justify-content: center;
    }

    #snapshot-card-1-parent-col-2 {
      text-align: center;
    }

    #snapshot-card-1-parent-div-1 {
      position: relative;
      width: 80px;
      height: 70px;
    }

    #snapshot-card-1-parent-div-2 {
      position: relative;
      width: 70px;
      height: 120px;
    }

    #snapshot-card-1-img-gmail {
      width: 30px;
      position: absolute;
      top: 15px;
      left: 0px;
    }

    #snapshot-card-1-img-snapshot, #snapshot-card-2-img-snapshot {
      width: 100%;
    }

    #snapshot-card-1-img-telegram {
      width: 30px;
      position: absolute;
      bottom: 0px;
      left: 17px;
    }

    #snapshot-card-1-img-arrow-1 {
      width: 40px;
      position: absolute;
      bottom: 20px;
      right: 0px;
    }

    #snapshot-card-1-img-arrow-2 {
      width: 40px;
      position: absolute;
      bottom: 40px;
      right: 0px;
    }

    #snapshot-card-2-img-arrow-1 {
      width: 40px;
      position: absolute;
      bottom: -20px;
      left: -20px;
    }

    #snapshot-card-2-img-arrow-2 {
      width: 40px;
      position: absolute;
      top: 10px;
      left: -20px;
    }

    #snapshot-card-2-parent-div-1 {
      position: relative;
      width: 80px;
      height: 70px;
    }

    #snapshot-card-2-parent-div-2 {
      padding-bottom: 20px;
      position: relative;
      width: 80px;
      height: 180px;
    }

    #snapshot-card-2-img-messenger {
      width: 30px;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    #snapshot-card-2-img-whatsapp {
      width: 36px;
      position: absolute;
      top: 5px;
      right: 20px;
    }

    #snapshot-card-2-parent-col-2 {
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      #snapshot-card-1 {
        display: none;
      }
      #snapshot-card-2 {
        display: none;
      }
      #snapshot-card-3 {
        display: block;
      }
    }

  }

  .snapshot-image-parent {
    display: flex;
    align-content: center;
    flex-direction: column;
    height: 400px;
    justify-content: center;
  }

  .goldstars-parent {

    padding: 30px 0;
    margin: 50px -30px;
    text-align: center;

    .title-parent {
      text-align: center;
    }

    h4 {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }

    p.explained {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #2B3841;
      max-width: 700px;
      margin: auto;
    }

    .cards-parent {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
    }

    @media screen and (max-width: 767px) {
      p.explained {
        display: none;
      }
    }

  }

  p.recognised-by {
    padding-top: 40px;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    align-items: center;
    text-align: center;
    opacity: 0.32;
  }

  .sponsors-parent {
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 100px;

    a.sponsor {
      margin: 10px;

      img {
        width: 140px;
      }

    }

    @media screen and (max-width: 350px) {

      a.sponsor {
        margin: 20px;
      }

    }

  }

}
