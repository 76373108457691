@import "../../style/app";

.partner-card {

  position: relative;
  margin: 20px 0;
  width: 100%;
  min-width: 348px;
  max-width: 348px;
  height: 240px;
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
  border-radius: 12px;

  div.partner-details {
    height: 190px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    img.logo {
      margin-top: 35px;
    }

    p.name {
      margin-top:26px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      color: #FFFFFF;
    }

    img.logo-placeholder {
      margin-top: 50px;
    }

    p.name-placeholder {
      margin-top:30px;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: 900;
      font-size: 17px;
      color: #004C22;
    }

  }

  img.partner {
    width: 300px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  a {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: $kujakuja-black;
  }

  a.admin-login {
    position: absolute;
    right: 18px;
    bottom: 15px;
  }

  a.public-data {
    position: absolute;
    left: 18px;
    bottom: 15px;
  }

  a.get-in-touch {
    margin-top: 16px;
  }

  span.coming-soon {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.24);
    margin-top: 16px;
  }

}

@media screen and (max-width: 991px) {

  .partner-card {
    margin: 20px auto !important;
  }

}
