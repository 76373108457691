@import "../../style/app";

.privacy-policy {

  margin: 50px 0;

  h3, h4 {
    margin: 50px 0 10px 0;
  }

  .top-padding{
    padding-top:30px;
  }

  h3{
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 30px;
  }

  h4{
    font-size: 28px;
    line-height: 32px;
  }




}
