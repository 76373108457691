@import "../../style/app";

.gold-star-card {

  position: relative;
  margin: 10px;
  width: 356px;
  height: 460px;
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(23, 29, 33, 0.14);
  border-radius: 12px;
  flex-wrap: wrap;

  img.story {
    width: 356px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  p.description {

    font-size: 16px;
    line-height: 28px;
    margin: 10px 5px 5px 20px;
    color: #2E3032;
    text-align: left;
  }

  a.full-story {
    position: absolute;
    right: 15px;
    bottom: 15px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: $kujakuja-black;
  }

}
