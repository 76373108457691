@import "../../style/app";

.raise-voice-parent {

  text-align: center;
  color: blue;
  border-radius: 12px;

  h1.raise-voice {
    font-size: 30px;
    line-height: 40px;
    color: white;
    background-color: darkblue;
    font-weight: bold;
  }

  a {
    color: blue;
  }

  span{
    margin-left:4px;
  }

}
